import './App.css';
import THEME from './constants/colors';
import React, { useEffect, useRef, useState } from "react";
const { ApolloClient, InMemoryCache, ApolloProvider, gql, fromPromise } = require('@apollo/client/core');
const {split, HttpLink,from} = require ('@apollo/client/core');
const { onError } = require("@apollo/client/link/error");
const {fetch} = require("cross-fetch");
const COPY_ICON_GREY = require('./assets/icons/copyIconGrey.png')
const COPY_ICON_BLACK = require('./assets/icons/copyIconBlack.png')
const TWITTER_ICON_GREY = require('./assets/icons/twitterIconGrey.png')
const TWITTER_ICON_BLACK = require('./assets/icons/twitterIconBlack.png')
const localStorage = require('local-storage');
export let globalApolloClient = null;

const errorLink = onError( ({ graphQLErrors, networkError, operation, forward }) => {
    console.log('In Error Link')
    if (graphQLErrors) {
    }
    else if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
    else{
      console.log('Something Else is Wrong');
    }
  });

const serverEndpoint = 'apiunifi.pace.cash/graphql'

const httpLink = new HttpLink({
    uri: 'https://'.concat(serverEndpoint), 
    fetch:fetch,
});

const apolloClient = new ApolloClient({
link: from([errorLink,httpLink]),
cache: new InMemoryCache(),
});

// console.log('apolloClient')
// console.log(apolloClient)

globalApolloClient = apolloClient;

const CREATE_USER_LOGIN_REQUEST = gql`
mutation($mailAddress:String! ){
  createUserLoginRequest(data:{
          mailAddress:$mailAddress,
        })
}`;

const LOGIN_USER = gql`
mutation($mailAddress:String! $otp:Int!){
  loginUser(data:{
          mailAddress:$mailAddress,
          otp:$otp,
        })
}`;

let loggedInMailAddress = localStorage.get('loggedInMailAddress');
let loggedInAPIKey = localStorage.get('loggedInAPIKey');  
console.log('loggedInMailAddress',loggedInMailAddress)
console.log('loggedInAPIKey',loggedInAPIKey)

function App() {

  const [isCopyButtonHover,setIsCopyButtonHover] =useState(false);
  const [isLogOutButtonHover,setIsLogOutButtonHover] =useState(false);
  const [isDocsButtonHover,setIsDocsButtonHover] =useState(false);
  const [isTwitterButtonHover,setIsTwitterButtonHover] = useState(false);

  const handleMouseEnterDocsButton = () => {
    setIsDocsButtonHover(true);
 };
 const handleMouseLeaveDocsButton = () => {
    setIsDocsButtonHover(false);
 };
  const handleMouseEnterCopyButton = () => {
    setIsCopyButtonHover(true);
 };
 const handleMouseLeaveCopyButton = () => {
    setIsCopyButtonHover(false);
 };

 const handleMouseEnterLogOutButton = () => {
  setIsLogOutButtonHover(true);
};
const handleMouseLeaveLogOutButton = () => {
  setIsLogOutButtonHover(false);
};

const handleMouseEnterTwitterButton = () => {
  setIsTwitterButtonHover(true);
};
const handleMouseLeaveTwitterButton = () => {
  setIsTwitterButtonHover(false);
};

  const [mailAddress,setMailAddress] =useState("");
  const [otp,setOTP] =useState("");
  const [UIType,setUIType] =useState( (loggedInMailAddress != null && loggedInAPIKey!= null)?"LOGGED_IN":"GET_OTP");

  return (
    <div style={{display:'flex', backgroundColor:THEME.BACKGROUND_PRIMARY, minHeight:'100vh', minWidth:'100vw', flexDirection:'column', justifyContent:'center'}}>
      <div style={{display:'flex', flexDirection:'row', alignSelf:'center', width:'500px',marginTop:'5px', marginBottom:'5px', justifyContent:'space-between'}}>
        <button style={{display:'flex',alignSelf:'center',justifyContent:'center', outline:'none', borderColor:'transparent', backgroundColor:'transparent', cursor:'pointer'}}
        onClick={()=>{
          window.open('https://unifiapi.xyz', "_blank", "noreferrer");
        }}>
          <span style={{display:'flex', color:THEME.TEXT_PRIMARY, fontSize:'60px',fontFamily:'Syne', fontWeight:'bold', alignSelf:'center'}}>unifi</span>
        </button>

        <button style={{display:'flex', flexDirection:'row', alignSelf:'center', backgroundColor:isDocsButtonHover?THEME.TEXT_PRIMARY:THEME.BACKGROUND_PRIMARY, width:'100px', height:'45px', justifyContent:'center', borderRadius:'20px', borderColor:isDocsButtonHover?'transparent':THEME.TEXT_PRIMARY, borderStyle:'solid', cursor:'pointer', marginLeft:'40px'}}
            onClick={()=>{
              // window.location.href = 'https://docs.unifiapi.xyz'
              window.open('https://docs.unifiapi.xyz', "_blank", "noreferrer");
              // window.location.replace()
            }}
            onMouseEnter={handleMouseEnterDocsButton}
            onMouseLeave={handleMouseLeaveDocsButton}
            >
            <span style={{display:'flex', color:isDocsButtonHover?THEME.BACKGROUND_PRIMARY:THEME.TEXT_PRIMARY, fontSize:'20px',fontFamily:'Syne', fontWeight:'bold', alignSelf:'center', marginRight:'5px'}}>docs</span>
        </button>
      </div>


      {(UIType == 'LOGGED_IN' && (loggedInMailAddress != null && loggedInAPIKey!= null))?
        <div style={{display:'flex', alignSelf:'center', flexDirection:'column'}}>
          <div style={{display:'flex', flexDirection:'column', alignSelf:'center', backgroundColor:THEME.TEXT_PRIMARY, width:'25vw', height:'40px', justifyContent:'center',marginTop:'20px', borderRadius:'20px'}}>
            <span style={{display:'flex', color:THEME.BACKGROUND_PRIMARY, fontSize:'20px',fontFamily:'Syne', fontWeight:'bold', alignSelf:'center'}}>{loggedInMailAddress}</span>
          </div>
          <span style={{display:'flex', color:THEME.TEXT_PRIMARY, fontSize:'20px',fontFamily:'Syne', fontWeight:'bold', alignSelf:'center',marginTop:'20px'}}>your api key</span>
          <div style={{display:'flex', flexDirection:'row', alignSelf:'center', backgroundColor:THEME.TEXT_PRIMARY, width:'35vw', height:'40px', justifyContent:'center',marginTop:'10px', borderRadius:'20px', marginTop:'20px', borderColor:'transparent', borderStyle:'solid'}}>
            <span style={{display:'flex', color:THEME.BACKGROUND_PRIMARY, fontSize:'20px',fontFamily:'Syne', fontWeight:'bold', alignSelf:'center'}}>{loggedInAPIKey}</span>
          </div>

          <button style={{display:'flex', flexDirection:'row', alignSelf:'center', backgroundColor:isCopyButtonHover?THEME.TEXT_PRIMARY:THEME.BACKGROUND_PRIMARY, width:'200px', height:'45px', justifyContent:'center',marginTop:'10px', borderRadius:'20px', marginTop:'20px', borderColor:isCopyButtonHover?'transparent':THEME.TEXT_PRIMARY, borderStyle:'solid', cursor:'pointer'}}
          onClick={()=>{
            navigator.clipboard.writeText(loggedInAPIKey)
          }}
          onMouseEnter={handleMouseEnterCopyButton}
          onMouseLeave={handleMouseLeaveCopyButton}
          >
          <span style={{display:'flex', color:isCopyButtonHover?THEME.BACKGROUND_PRIMARY:THEME.TEXT_PRIMARY, fontSize:'20px',fontFamily:'Syne', fontWeight:'bold', alignSelf:'center', marginRight:'5px'}}>copy key</span>
          {(isCopyButtonHover)?
          <img src={COPY_ICON_GREY} style={{width:'25px', height:'25px', alignSelf:'center', marginLeft:'5px'}}/>
          :
          <img src={COPY_ICON_BLACK} style={{width:'25px', height:'25px', alignSelf:'center', marginLeft:'5px'}}/>
          }
          </button>

          <button style={{display:'flex', flexDirection:'row', alignSelf:'center', backgroundColor:isLogOutButtonHover?THEME.TEXT_PRIMARY:THEME.BACKGROUND_PRIMARY, width:'150px', height:'45px', justifyContent:'center',marginTop:'10px', borderRadius:'20px', marginTop:'40px', borderColor:isLogOutButtonHover?'transparent':THEME.TEXT_PRIMARY, borderStyle:'solid', cursor:'pointer'}}
          onClick={()=>{
            localStorage.set('loggedInMailAddress',null);
            localStorage.set('loggedInAPIKey',null);
            setOTP("");
            setMailAddress("")
            setUIType('GET_OTP')
          }}
          onMouseEnter={handleMouseEnterLogOutButton}
          onMouseLeave={handleMouseLeaveLogOutButton}
          >
          <span style={{display:'flex', color:isLogOutButtonHover?THEME.BACKGROUND_PRIMARY:THEME.TEXT_PRIMARY, fontSize:'20px',fontFamily:'Syne', fontWeight:'bold', alignSelf:'center'}}>log out</span>
          </button>
        </div>
        :
        <div style={{display:'flex', alignSelf:'center', justifyContent:'center', marginTop:'10px', flexDirection:'column'}}>
          <span style={{display:'flex', color:THEME.TEXT_PRIMARY, fontSize:'25px',fontFamily:'Syne', fontWeight:'bold', alignSelf:'center', marginBottom:'5px'}}>login with otp.</span>
          <span style={{display:'flex', color:THEME.TEXT_PRIMARY, fontSize:'25px',fontFamily:'Syne', fontWeight:'bold', alignSelf:'center', marginTop:'5px', marginBottom:'5px'}}>get free api-key.</span>
          <span style={{display:'flex', color:THEME.TEXT_PRIMARY, fontSize:'25px',fontFamily:'Syne', fontWeight:'bold', alignSelf:'center', marginTop:'5px', marginBottom:'5px'}}>head to docs & use Unifi.</span>
        </div>
      }

        
      {(UIType == "GET_OTP")?
        <input
          style={{display:'flex', width:'25vw', height:'50px', backgroundColor:'transparent', borderColor:THEME.TEXT_PRIMARY, borderWidth:'2px', outline:'none',borderRadius:'16px', alignSelf:'center', textAlign:'center', borderStyle:'solid'
          , fontFamily:'Syne', fontWeight:'bold', fontSize:'20px', marginTop:'30px'}}
          value={mailAddress}
          placeholder={"your mail address"}
          onChange={(e) => {
            setMailAddress(e.target.value);
          }}
        />:null}

        {(UIType == "GET_OTP")?
        <button
          style={{display:'flex', width:'150px', height:'50px', backgroundColor:THEME.TEXT_PRIMARY, borderColor:THEME.TEXT_PRIMARY, borderWidth:'2px', outline:'none',borderRadius:'16px', alignSelf:'center', justifyContent:'center', borderStyle:'solid'
          , fontFamily:'Syne', fontWeight:'bold', fontSize:'14px', marginTop:'40px', cursor:'pointer'}}
          onClick={async ()=>{
              console.log('clicked')
              let mutationResponse = await globalApolloClient.mutate({
              mutation:CREATE_USER_LOGIN_REQUEST,
              variables:{
                  mailAddress:mailAddress,
              }
          })
          if(mutationResponse.data.createUserLoginRequest.message == 'LOGIN REQUEST CREATED PLEASE VERIFY OTP'){
                setUIType("VERIFY_OTP")
              }
          console.log('mutationResponse',mutationResponse)

          }}>
            <span style={{display:'flex', color:THEME.BACKGROUND_PRIMARY, fontSize:'20px',fontFamily:'Syne', fontWeight:'bold', alignSelf:'center'}}>get OTP</span>
          </button>:
          (UIType == "VERIFY_OTP")?
          <input
          style={{display:'flex', width:'15vw', height:'40px', backgroundColor:'transparent', borderColor:THEME.TEXT_PRIMARY, borderWidth:'2px', outline:'none',borderRadius:'16px', alignSelf:'center', textAlign:'center', borderStyle:'solid'
          , fontFamily:'Syne', fontWeight:'bold', fontSize:'20px', marginTop:'30px'}}
          value={otp}
          placeholder={"enter OTP"}
          onChange={(e) => {
            setOTP(e.target.value);
          }}
        />:null}

        {(UIType == "VERIFY_OTP")?
        <button
          style={{display:'flex', width:'150px', height:'50px', backgroundColor:THEME.TEXT_PRIMARY, borderColor:THEME.TEXT_PRIMARY, borderWidth:'2px', outline:'none',borderRadius:'16px', alignSelf:'center', justifyContent:'center', borderStyle:'solid'
          , fontFamily:'Syne', fontWeight:'bold', fontSize:'14px', marginTop:'30px', cursor:'pointer'}}
          onClick={async ()=>{
              console.log('clicked')
              let mutationResponse = await globalApolloClient.mutate({
              mutation:LOGIN_USER,
              variables:{
                  mailAddress:mailAddress,
                  otp:parseInt(otp),
              }
          })
          console.log('mutationResponse',mutationResponse)
            if(mutationResponse.data.loginUser.message == 'VERIFICATION SUCCESSFUL'){
              //login Successful
              localStorage.set('loggedInAPIKey',mutationResponse.data.loginUser.apiKey);
              localStorage.set('loggedInMailAddress',mailAddress);
              loggedInMailAddress = mailAddress
              loggedInAPIKey = mutationResponse.data.loginUser.apiKey
              setUIType('LOGGED_IN');
              // show API and Button to Docs
            }
            else{
              setUIType('VERIFICATION_FAILED');
              setOTP("");
              setMailAddress("")
              setTimeout(()=>{              
                setUIType('GET_OTP');},2500)
            }
          }}>
            <span style={{display:'flex', color:THEME.BACKGROUND_PRIMARY, fontSize:'20px',fontFamily:'Syne', fontWeight:'bold', alignSelf:'center'}}>get API key</span>
          </button>:null}

        {(UIType == "VERIFICATION_FAILED")?
        <span style={{display:'flex', color:THEME.TEXT_FAILURE, fontSize:'20px',fontFamily:'Syne', fontWeight:'bold', alignSelf:'center', marginTop:'20px'}}>OTP Verification Failed, Try again</span>
        :null}

        <button style={{display:'flex', flexDirection:'row', alignSelf:'center', backgroundColor:isTwitterButtonHover?THEME.TEXT_PRIMARY:THEME.BACKGROUND_PRIMARY, width:'280px', height:'45px', justifyContent:'center', borderRadius:'20px', marginTop:'55px', borderColor:'transparent', borderStyle:'solid', cursor:'pointer'}}
          onClick={()=>{
            // localStorage.set('loggedInMailAddress',null);
            // localStorage.set('loggedInAPIKey',null);
            // setUIType('GET_OTP')
            window.open('https://twitter.com/aakash_hash', "_blank", "noreferrer");
          }}
          onMouseEnter={handleMouseEnterTwitterButton}
          onMouseLeave={handleMouseLeaveTwitterButton}
          >
        <span style={{display:'flex', color:(isTwitterButtonHover)?THEME.BACKGROUND_PRIMARY:THEME.TEXT_PRIMARY, fontSize:'15px',fontFamily:'Syne', fontWeight:'bold', alignSelf:'center'}}>reach out to the builder</span>
        
        {(isTwitterButtonHover)?
          <img src={TWITTER_ICON_GREY} style={{width:'25px', height:'25px', alignSelf:'center', marginLeft:'8px'}}/>
          :
          <img src={TWITTER_ICON_BLACK} style={{width:'25px', height:'25px', alignSelf:'center', marginLeft:'8px'}}/>
          }
        </button>
    </div>
  );
}

export default App;
